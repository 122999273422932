<template>
  <div>
    <el-alert v-if="hasEditedFields" type="warning" show-icon style="margin-bottom: 15px" :closable="false"> Ci sono modifiche non salvate </el-alert>
    <el-card class="box-card">
      <h3 v-if="currentCondominium">Info condominio - {{currentCondominium.description}}</h3>
      <el-descriptions v-if="currentCondominium" :column="3" border class="margin-top table-fixed" direction="vertical" title="Dati di Sistema">
        <template #extra>
          <el-button v-if="hasEditedFields" type="primary" @click="saveEdits">
            <i class="el-icon-upload"></i> Salva Modifiche
          </el-button>
        </template>
        <el-descriptions-item v-for="condo in descriptionFields.system" :key="condo.field" :span="condo.span">
          <template #label>
            <div class="justify-space-between" style="display: flex; align-items: flex-end">
              <span>
                <i :class="condo.icon"></i> {{ condo.label }}
              </span>
              <span style="float: right" v-if="condo.editable && isGranted('ROLE_MANAGER')" class="pointer edit-button" @click="condo.onEdit(condo)"><i class="el-icon-edit"></i>Modifica</span>
            </div>
          </template>
          <span v-if="condo.onClick" class="pointer" @click="condo.onClick">{{ condo.value }}</span>
          <span v-else>{{ condo.value }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions v-if="currentCondominium" :column="3" border class="margin-top table-fixed" direction="vertical" title="Dati Anagrafici">
        <template #extra>
          <el-button v-if="hasEditedFields" type="primary" @click="saveEdits">
            <i class="el-icon-upload"></i> Salva Modifiche
          </el-button>
        </template>
        <el-descriptions-item v-for="condo in descriptionFields.registry" :key="condo.field" :span="condo.span">
          <template #label>
            <div class="justify-space-between" style="display: flex; align-items: flex-end">
              <span>
                <i :class="condo.icon"></i> {{ condo.label }}
              </span>
              <span style="float: right" v-if="condo.editable && isGranted('ROLE_MANAGER')" class="pointer edit-button" @click="condo.onEdit(condo)"><i class="el-icon-edit"></i>Modifica</span>
            </div>
          </template>
          <span v-if="condo.onClick" class="pointer" @click="condo.onClick">{{ condo.value }}</span>
          <span v-else>{{ condo.value }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions v-if="currentCondominium" :column="3" border class="margin-top table-fixed" direction="vertical" title="Dati Gestionali">
        <template #extra>
          <el-button v-if="hasEditedFields" type="primary" @click="saveEdits">
            <i class="el-icon-upload"></i> Salva Modifiche
          </el-button>
        </template>
        <el-descriptions-item v-for="condo in descriptionFields.technical" :key="condo.field" :span="condo.span">
          <template #label>
            <div class="justify-space-between" style="display: flex; align-items: flex-end">
              <span>
                <i :class="condo.icon"></i> {{ condo.label }}
              </span>
              <span style="float: right" v-if="condo.editable && isGranted('ROLE_MANAGER')" class="pointer edit-button" @click="condo.onEdit(condo)"><i class="el-icon-edit"></i>Modifica</span>
            </div>
          </template>
          <span v-if="condo.field==='transmissionType'"><i class="el-icon-s-opportunity" :class="transmissionTypeColor" ></i> </span>
          <span v-if="condo.onClick" class="pointer" @click="condo.onClick">{{ condo.value }}</span>
          <span v-else>{{ condo.value }}</span>

        </el-descriptions-item>
      </el-descriptions>

      <simple-input-dialog ref="simpleInputDialog" @submit="updateCurrentField"></simple-input-dialog>
      <form-dialog ref="addressDialog" :fields="addressFields" title="Modifica Indirizzo"
                   @submit="onUpdateAddress"></form-dialog>
      <simple-select-dialog ref="simpleSelectDialog" @submit="updateCurrentSelect"></simple-select-dialog>
    </el-card>
    <el-card v-if="isGranted('ROLE_CONDO') && currentCondominium">
      <h4><span class="el-icon-setting"></span> Operazioni </h4>
      <div>
        <el-button disabled type="primary"><i class="el-icon-download"></i> Esegui letture</el-button>
        <el-button disabled type="primary"><i class="el-icon-phone-outline"></i> Richiedi Intervento</el-button>
        <el-button type="primary" @click="dialogPrintUsers=true"><i class="el-icon-view"></i> {{$t("buttons.condominium.print")}} </el-button>
      </div>

      <h4><span class="el-icon-lock"></span> Amministrazione </h4>
      <div v-if="isGranted('ROLE_MANAGER')">
        <el-button  icon="el-icon-plus" type="primary" @click="openCreateApartmentDialog">
          Aggiungi Appartamento
        </el-button>
        <el-button  icon="el-icon-edit-outline" type="primary" @click="openReplaceMetersDialog">
          Sostituzione contatori
        </el-button>
        <el-button v-if="currentCondominium.active" icon="el-icon-remove" type="warning" @click="openDisableCondoDialog">
          Disattiva Condominio
        </el-button>
        <el-button v-else icon="el-icon-unlock" type="warning" @click="openDisableCondoDialog">
          Attiva Condominio
        </el-button>
      </div>



    </el-card>
    <el-card v-if="currentCondominium" class="box-card">
      <apartment-list :key="apartments"></apartment-list>
    </el-card>
    <form-dialog v-if="isGranted('ROLE_MANAGER')" ref="createApartmentDialog" :fields="newApartmentFields" :footer-notes="footerNotes"
                 :title="'Crea Appartamento'" @submit="submittedCreateApartment"></form-dialog>
    <el-dialog v-model="dialogDisableCondo" title="Attenzione" width="30%" >
      <el-alert type="warning" :description=getDialogText() show-icon :closable="false"></el-alert>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogDisableCondo = false">Annulla</el-button>
        <el-button type="warning" @click="submitDisableCondo">Conferma</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="dialogPrintUsers">
      <el-button primary @click="printCsv"> Scarica csv </el-button>
      <el-table :data="currentCondominium.apartments">
        <el-table-column border="true" :key="key" v-for="(label, key) in printUserFields" :prop="label" :label="label">
          <template #default="scope"> {{key==='fullname' ? getFullName(scope.row.user) : scope.row.user[key] }} </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <date-picker-dialog ref="datePickerDialog" @submit="updateCurrentDate"></date-picker-dialog>
    <replace-meters-dialog ref="replaceMetersDialog"></replace-meters-dialog>
  </div>
</template>

<script>
import UserMixin from "../../mixins/UserMixin";
import AddressMixin from "../../mixins/AddressMixin";
import FormDialog from "../../components/FormDialog";
import SimpleInputDialog from "../../components/SimpleInputDialog";
import SimpleSelectDialog from "../../components/SimpleSelectDialog";
import DatePickerDialog from "../../components/DatePickerDialog";
import {mapActions, mapGetters} from "vuex";
import ApartmentList from "./ApartmentList";
import {getAddressFields, getNewApartmentFields} from "../../static/formFields";
import DateMixin from "../../mixins/DateMixin";
import ReplaceMetersDialog from "../../components/ReplaceMetersDialog";


export default {
  name: "Condominium",
  mixins: [UserMixin, AddressMixin, DateMixin],
  components: {ApartmentList, FormDialog, SimpleInputDialog, SimpleSelectDialog, DatePickerDialog, ReplaceMetersDialog},
  data() {
    return {
      descriptionFields: {},
      addressFields: [],
      newApartmentFields: [],
      hasEditedFields: false,
      editedFields: {},
      currentField: null,
      hasRole: {
        ROLE_MANAGER: false,
        ROLE_ADMIN: false,
        ROLE_CONDO: false,
      },
      apartments: false,
      footerNotes: "Se nome e/o cognome vengono valorizzati, allora viene creata una nuova utenza. Altrimenti l'appartamento viene generato senza utente.",
      dialogDisableCondo: false,
      activeStatus: true,
      imgSourceOptions: {
        "default" : "Default",
        "owner": "Cliente Sunny",
        "condominiumAdministrator" : "Amministratore Condominiale",
        "supplier": "Gestore Calore/Acqua",
        "serviceTechnician": "Ditta Assistenza Tecnica"
      },
      dialogPrintUsers: false,
      printUserFields: {
        "email" : "Username",
        "userCode": "Password",
        "fullname": "Nominativo",
      }
  }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentCondominium",
      "appUser",
      "isGranted",
      "condominiumAdministratorList"
    ]),
    transmissionTypeColor(){
      if (this.currentCondominium.transmissionType)
        return "dark-blue";
      else{
        return "light-yellow";
      }
    }
  },
  methods: {
    ...mapActions(["fetchUser", "fetchCondominium", "fetchAllCondominiumAdministrators", "updateCondominium", "fetchAllBasicUsers", "createApartment", "disableCondominium", "activateCondominium"]),
    editField(field) {
      this.currentField = field;
      this.$refs.simpleInputDialog.title = field.label;
      this.$refs.simpleInputDialog.formData = field.value;
      this.$refs.simpleInputDialog.show();
    },
    updateCurrentField(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = data;
    },
    updateCurrentDate(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = this.stringifyReadingDate(data);
      //console.log(this.currentField)
    },
    async editSelect(field) {
      this.currentField = field;
      this.$refs.simpleSelectDialog.title = field.label;
      this.$refs.simpleSelectDialog.formData = field.value;
      await this.fetchAllCondominiumAdministrators();

      this.$refs.simpleSelectDialog.items = this.condominiumAdministratorList.map(el => ({
        value: el.id,
        label: this.getFullName(el)
      }))
      this.$refs.simpleSelectDialog.show();
    },
    async updateCurrentSelect(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = this.getFullName(this.condominiumAdministratorList.find(u => u.id === data)) || this.getImgSourceText(data);
    },
    editAddress(field) {
      this.currentField = field;
      this.addressFields = getAddressFields(this.currentCondominium.address || {});
      this.$refs.addressDialog.show();
    },
    editImgSource(field){
      this.currentField = field;
      this.$refs.simpleSelectDialog.title = field.label;
      this.$refs.simpleSelectDialog.formData = field.value;
      //console.log(this.imgSourceOptions.entries())
      this.$refs.simpleSelectDialog.items = Object.entries(this.imgSourceOptions).map((el) => ({
        value: el[0],
        label: this.getImgSourceText(el[0])
      }));
      // console.log(this.$refs.simpleSelectDialog.items)
      this.$refs.simpleSelectDialog.show();
    },
    onUpdateAddress(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data.address;
      this.currentField.value = this.stringifyAddress(data.address);
    },
    async saveEdits() {
      await this.updateCondominium({...this.editedFields});
      this.hasEditedFields = false;
    },
    async openCreateApartmentDialog() {
      this.newApartmentFields = getNewApartmentFields();
      this.$refs.createApartmentDialog.show();
    },
    openDisableCondoDialog() {
      this.dialogDisableCondo = true;
    },
    async submitDisableCondo(){
      this.dialogDisableCondo = false;
      if (this.currentCondominium.active){
        await this.disableCondominium();
      }
      else {
        await this.activateCondominium();
      }
      this.descriptionFields.forEach( obj => {
        if (obj.field === "active"){
          obj.value = this.getStatusText()
        }
      })
    },
    async submittedCreateApartment(data) {
      await this.createApartment({...data});
    },
    async goToUser(user){
      await this.$router.push("/dashboard/utente/" + user.id);
    },
    getDialogText(){
      if (this.currentCondominium.active){
        return "Sei sicuro di voler disabilitare questo condominio?";
      }
      return "Sei sicuro di voler riattivare questo condominio?";
    },
    getStatusText(){
      return this.currentCondominium.active ? "ATTIVO" : "DISATTIVATO"
    },
    getImgSourceText(imgSource){
      if (!imgSource || imgSource==="default"){
        return "Default: Sunny";
      }
      return this.imgSourceOptions[imgSource] +": "+this.getFullName(this.currentCondominium[imgSource]);
    },
    printCsv(){
      const separator = ",";
      const headers = "data:text/csv;charset=utf-8," + Object.values(this.printUserFields).join(separator);
      let csv = headers+ "\r\n";
      this.currentCondominium.apartments.map((ap) => (ap.user)).forEach( (usr) => {
        let row = "";
        //console.log(usr)
        Object.keys(this.printUserFields).forEach( (field) => {
          //console.log(field)
          field==='fullname' ? row+= this.getFullName(usr)+separator : row+= usr[field] + separator;
        });
        csv += row + "\r\n";
        //console.log(row);
      });

      // console.log(csv);
      let encodedUri = encodeURI(csv);
      window.open(encodedUri);

    },
    editReadingStartDate(field){
      this.currentField=field;
      this.$refs.datePickerDialog.title = "Scegli la data inizio stagione";
      this.$refs.datePickerDialog.dateDay = 1
      this.$refs.datePickerDialog.dateMonth = "Set"
      this.$refs.datePickerDialog.dialog = true;
      this.$refs.datePickerDialog.date = this.currentCondominium.readingsStartDate;
    },
    editReadingEndDate(field){
      this.currentField=field;
      this.$refs.datePickerDialog.title = "Scegli la data fine stagione";
      this.$refs.datePickerDialog.dateDay = 30
      this.$refs.datePickerDialog.dateMonth = "Giu"
      this.$refs.datePickerDialog.dialog = true;
      this.$refs.datePickerDialog.date = this.currentCondominium.readingsEndDate;
    },
    getConfirm() {
      if (this.hasEditedFields)
        return 'Are you sure you want to leave the current page? \n(All unsaved changes will be lost)';
    },
    createFields(){
      this.descriptionFields["system"] = [
        {
          field: "owner",
          value: this.getFullName(this.currentCondominium.owner),
          label: "Cliente Sunny",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
          onClick: () => this.goToUser(this.currentCondominium.owner)
        }, {
          field: "condominiumAdministrator",
          value: this.getFullName(this.currentCondominium.condominiumAdministrator),
          label: "Amministratore di Condominio",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
          onClick: () => this.goToUser(this.currentCondominium.condominiumAdministrator)
        }, {
          field: "supplier",
          value: this.getFullName(this.currentCondominium.supplier),
          label: "Gestore Calore/Acqua",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
          onClick: () => this.goToUser(this.currentCondominium.supplier)
        }, {
          field: "serviceTechnician",
          value: this.getFullName(this.currentCondominium.serviceTechnician),
          label: "Ditta Assistenza Tecnica",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
          onClick: () => this.goToUser(this.currentCondominium.serviceTechnician)
        }, {
          field: "imgSource",
          value: this.getImgSourceText(this.currentCondominium.imgSource),
          label: "Immagine da visualizzare",
          icon: "el-icon-picture",
          span: 1,
          editable: true,
          onEdit: this.editImgSource,
        },
      ];

      this.descriptionFields["registry"] = [
        {
          field: "description",
          value: this.currentCondominium.description,
          label: "Denominazione",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "fiscalCode",
          value: this.currentCondominium.fiscalCode,
          label: "Codice Fiscale",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "address",
          value: this.stringifyAddress(this.currentCondominium.address),
          label: "Indirizzo",
          icon: "el-icon-location-outline",
          span: 1,
          editable: true,
          onEdit: this.editAddress,
        }
      ];
      console.log(this.currentCondominium)

      this.descriptionFields["technical"] = [
        {
          field: "readingsStartDate",
          value: this.stringifyReadingDate(this.currentCondominium.readingsStartDate),
          label: "Data Inizio Stagione",
          icon: "el-icon-date",
          span: 1,
          editable: true,
          onEdit: this.editReadingStartDate
        }, {
          field: "readingsEndDate",
          value: this.stringifyReadingDate(this.currentCondominium.readingsEndDate),
          label: "Data Fine Stagione",
          icon: "el-icon-date",
          span: 1,
          editable: true,
          onEdit: this.editReadingEndDate
        }, {
          field: "active",
          value: this.getStatusText(),
          label: "Stato",
          icon: "el-icon-info",
          span: 1,
          editable: false,
        },
        {
          field: "transmissionType",
          value: this.printTransmissionType(this.currentCondominium.transmissionType),
          label: "Tipo di trasmissione",
          icon: "el-icon-s-opportunity",
          span: 1,
          editable: false,
        },

      ];

    },
    openReplaceMetersDialog(){
      this.$refs.replaceMetersDialog.show();
    },
    printTransmissionType(type){
      if (type){
        return "Scuro";
      } else {
        return "Chiaro"
      }

    },

  },
  async created() {
    await this.fetchCondominium(this.$route.params.id);
    this.apartments = !this.apartments;
    // console.log(this.currentCondominium.apartments)
    this.createFields()
    window.onbeforeunload = () => {
      return this.getConfirm();
    };
  },
  watch: {
    async $route(to, from) {
      if (to !== from && to.name === from.name) {
        const condoId = this.$route.params.id;
        if (condoId){
          await this.fetchCondominium(condoId);
          this.createFields()
        }
      }
      //this.updateView = !this.updateView
    }
  }
}
</script>

<style>
.table-fixed .el-descriptions__body table {
  table-layout: fixed;
}

.el-button {
  margin: 5px 0;
}

.el-descriptions{
  margin: 20px 0;
}

.dark-blue{
  color: #0000ac;
}

.light-yellow{
  color: #ffcf42;
}

</style>
