<template>
  <div>
    <el-row style="margin-bottom: 15px; align-items: center;" justify="space-between">
      <el-col :span="8">
        <h4 style="margin: 0">Riepilogo consumi
          <span v-if="currentCondominium && currentCondominium.lastReadingDate">
            aggiornato al {{getLastReadingDate()}}
          </span>
        </h4>
      </el-col>
      <el-col :span="8">
        <el-input placeholder="Cerca per utente" v-model="query" @clear="clearInput" clearable></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-descriptions class="margin-top" :column="3" border v-if="apartmentList">
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-data-line"></i>
              Totale Consumi Condominio
            </template>
            {{ totalConsumption }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-data-line"></i>
              Media Consumi Condominio
            </template>
            {{ averageConsumption.toFixed(2) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-data-line"></i>
              K Totale
            </template>
            {{ totalK }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="apartmentList">
        <el-table
            :data="apartmentList.filter( (ap) =>
          !query || getFullName(ap.user).toLowerCase().includes(query.toLowerCase())
        )">
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="floor" label="Piano/Scala"></el-table-column>
          <el-table-column prop="number" label="Numero"></el-table-column>
          <el-table-column v-if="!self" prop="user" label="Utente">
            <template #default="scope">
              {{ getFullName(scope.row.user) }}
            </template>
          </el-table-column>
          <el-table-column v-if="self" prop="condominium" label="Condominio">
            <template #default="scope">
              {{ stringifyAddress(scope.row.condominium.address) }}
            </template>
          </el-table-column>
          <el-table-column prop="description" label="Descrizione"></el-table-column>
          <!--          <el-table-column prop="kApartment" label="K" align="right"></el-table-column>-->
          <el-table-column prop="targetComsumption" label="Consumo ideale" align="right">
            <template #default="scope">
              {{ (100 * scope.row.targetComsumption).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="consumption" label="Consumi" align="right">
            <template #default="scope">
              {{ scope.row.consumption }}
            </template>
          </el-table-column>
          <el-table-column prop="effectiveConsumption" label="Consumi%" align="right">
            <template #default="scope">
              {{ (100 * scope.row.effectiveConsumption).toFixed(2) }}%
              <el-tooltip class="item" effect="dark"
                          :content="'Differenza consumi: ' + (100 * scope.row.difference).toFixed(2) + '%'">
                <i :class="getIcon(scope.row.difference)"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="70">
            <template #default="scope">
              <el-tooltip class="item" effect="dark" content="Clicca per visualizzare le info dell'appartamento">
                <el-button @click="apartmentSelected(scope.row)" type="primary" circle size="small" icon="el-icon-d-arrow-right"> </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AddressMixin from "../../mixins/AddressMixin";
import UserMixin from "../../mixins/UserMixin";
import IconsMixin from "../../mixins/IconsMixin";
import {mapActions, mapGetters} from "vuex";
import DateMixin from "../../mixins/DateMixin";

export default {
  name: "ApartmentList",
  components: {},
  mixins: [AddressMixin, UserMixin, IconsMixin, DateMixin],
  data() {
    return {
      apartmentList: [],
      self: true,
      totalConsumption: 0,
      averageConsumption: 0,
      totalK: 0,
      query: ""
    }
  },
  computed: {
    ...mapGetters(["currentCondominium", "currentUser", "basicUserList", "appUser", "isGranted", "currentApartmentReadingTotals", "currentApartment"])
  },
  methods: {
    ...mapActions(["fetchAllBasicUsers", "createApartment", "fetchApartment","fetchCondominium" ,"fetchReadings"]),
    apartmentSelected(e) {
      this.$router.push("/dashboard/appartamento/" + e.id);
    },
    async createData(){
      if (this.currentCondominium) {
        this.apartmentList = this.currentCondominium.apartments;
        this.self = false;
      }
      //calcolo consumi
      this.totalConsumption = this.currentCondominium.totalConsumption;
      this.totalK = this.currentCondominium.totalK;

      // Calcolo Valori in norma
      this.apartmentList.forEach(apartment => {
        if (this.totalK > 0 && this.totalConsumption > 0) {
          apartment["targetComsumption"] = apartment.kApartment / this.totalK;
          apartment["effectiveConsumption"] = apartment.consumption / this.totalConsumption;
          //apartment["difference"] = this.getDifference(apartment);
          apartment["difference"] = (apartment["effectiveConsumption"] - apartment["targetComsumption"])/ apartment["targetComsumption"];
        } else {
          apartment["targetComsumption"] = 0;
          apartment["effectiveConsumption"] = 0;
        }
      });

      this.averageConsumption = this.totalConsumption / this.apartmentList.length;
    },
    getLastReadingDate(){
      return this.formatDate(this.currentCondominium.lastReadingDate)
    }
  },
  async created() {
    await this.createData();
  },
  watch: {
    async $route(to, from) {
      if (to !== from && to.name === from.name) {
        await this.fetchCondominium(this.$route.params.id);
        await this.createData()
      }
      //this.updateView = !this.updateView
    }
  }
}
</script>

<style scoped>
.color-danger {
  color: #F56C6C
}

.color-success {
  color: #67C23A
}

.color-warning {
  color: #E6A23C
}
</style>
