<template>
  <el-dialog :title="title" v-model="showDialog" :close-on-click-modal="false">
    <el-form>
      <el-form-item>
        <el-select v-model="formData">
          <el-option
              v-for="item in items"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss">Annulla</el-button>
        <el-button type="primary" @click="submit">Conferma</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script>
export default {
  name: "SimpleSelectDialog",
  emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      formData: "",
      title: "",
    }
  },
  methods: {
    show(){
      this.showDialog = true;
    },
    submit() {
      this.$emit("submit", this.formData);
      this.showDialog = false;
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    }
  }

}
</script>

<style scoped>

</style>

